import React from "react"
import { inject, observer } from "mobx-react"

const desktopStyle={position:'relative', overflow: 'hidden',width: '100%', height: '56.25%'}
const mobileStyle={position:'relative', overflow: 'hidden',width: '100%', height: '100%'}
const Video = ({ videoSrcURL, videoTitle, vidstyle, ...props }) => (
  <div className="video" style={props.store.device === "mobile" ? mobileStyle:desktopStyle}>
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
      style={{
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: '100%',
  height: '100%',
}}
    />
  </div>
)

export default inject("store")(observer(Video))